<template>
    <div class="reportEatserve defaultReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter selected">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="inline-block search-flex">
                    <div class="bnt-search" @click="GetBillCheck()" >查询</div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table headerCol2 isFooter" ref="tableBox"   >
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                ref="elTable"
                 border 
                 show-summary
                :summary-method="getSummaries"
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                    <el-table-column fixed prop="Rpt_Date" label="营业日期" min-width="90">
                        <template #default="scope">
                            <span class="link" >{{scope.row.Rpt_Date}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="WeekDay" label="星期" min-width="60"></el-table-column>
                    <el-table-column fixed prop="TotalDeskNum" label="总桌数" min-width="80" align="right" data-format="number"></el-table-column>
                    <el-table-column fixed prop="TotalPersonNum" label="来客数" min-width="80" align="right" data-format="number"></el-table-column>
                    <el-table-column label="按时间分析桌数">
                        <el-table-column prop="TimeNormalDeskNum" label="正常" min-width="80" align="right" data-format="number">
                            <template #default="scope">
                                <span class="link" v-if="scope.row.TimeNormalDeskNum!=0">{{scope.row.TimeNormalDeskNum}}</span>
                                <span  v-else>{{scope.row.TimeNormalDeskNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TimeOverDeskNum" label="超时" min-width="80" align="right" data-format="number">
                            <template #default="scope">
                                <span class="link" v-if="scope.row.TimeOverDeskNum!=0">{{scope.row.TimeOverDeskNum}}</span>
                                <span  v-else>{{scope.row.TimeOverDeskNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TimeOverDeskNumPercent" label="超时占比%" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                    </el-table-column>
                    <el-table-column label="按上菜顺序分析桌数">
                        <el-table-column prop="ServingNormalDeskNum" label="正常" min-width="80" align="right" data-format="number">
                            <template #default="scope">
                                <span class="link" v-if="scope.row.ServingNormalDeskNum!=0">{{scope.row.ServingNormalDeskNum}}</span>
                                <span  v-else>{{scope.row.ServingNormalDeskNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ServingOverDeskNum" label="未按顺序" min-width="80" align="right" data-format="number">
                            <template #default="scope">
                                <span class="link" v-if="scope.row.ServingOverDeskNum!=0">{{scope.row.ServingOverDeskNum}}</span>
                                <span  v-else>{{scope.row.ServingOverDeskNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ServingOverDeskNumPercent" label="未按顺序占比%" min-width="100" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                    </el-table-column>

                    <el-table-column label="按菜品维度分析超时">
                        <el-table-column prop="FoodTotalVerifyNum" label="考核总道数" min-width="80" align="right" data-format="number"></el-table-column>
                        <el-table-column prop="FoodVerifyNormalNum" label="正常道数" min-width="80" align="right" data-format="number"></el-table-column>
                        <el-table-column prop="FoodVerifyOverTimeNum" label="超时道数" min-width="80" align="right" data-format="number"></el-table-column>
                        <el-table-column prop="FoodVerifyOverTimeNumPercent" label="异常占比%" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                    </el-table-column>

                    <el-table-column label="催菜情况">
                        <el-table-column prop="RushDeskNum" label="含催菜桌数" min-width="80" align="right" data-format="number">
                            <template #default="scope">
                                <span class="link" v-if="scope.row.RushDeskNum!=0">{{scope.row.RushDeskNum}}</span>
                                <span  v-else>{{scope.row.RushDeskNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="RushDeskNumPercent" label="催菜桌数占比%" min-width="100" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                    </el-table-column>

                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
     </div>
</template>
<script>


/**上菜顺序速度异常监控表 */
export default {
    name:'reportEatserve',
    data(){
        return {
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        let Rpt_Date=new Date(this.userInfo.Rpt_Date);
        Rpt_Date.setDate(Rpt_Date.getDate()-1);
        this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        /**合计数据 */
        summaryTableList(){
            let total={
                TotalDeskNum:0,
                TotalPersonNum:0,
                TimeNormalDeskNum:0,
                TimeOverDeskNum:0,
                TimeOverDeskNumPercent:0,//超时占比%
                ServingNormalDeskNum:0,
                ServingOverDeskNum:0,
                ServingOverDeskNumPercent:0,//未按顺序占比%
                FoodTotalVerifyNum:0,
                FoodVerifyNormalNum:0,
                FoodVerifyOverTimeNum:0,
                FoodVerifyOverTimeNumPercent:0//异常占比%
            };//合计
            this.tableList?.forEach((it)=>{
                total.TotalDeskNum+=it.TotalDeskNum;
                total.TotalPersonNum+=it.TotalPersonNum;
                total.TimeNormalDeskNum+=it.TimeNormalDeskNum;
                total.TimeOverDeskNum+=it.TimeOverDeskNum;
                total.ServingNormalDeskNum+=it.ServingNormalDeskNum;
                total.ServingOverDeskNum+=it.ServingOverDeskNum;
                total.FoodTotalVerifyNum+=it.FoodTotalVerifyNum;
                total.FoodVerifyNormalNum+=it.FoodVerifyNormalNum;
                total.FoodVerifyOverTimeNum+=it.FoodVerifyOverTimeNum;
            });
            
            total.TimeOverDeskNumPercent=(total.TimeNormalDeskNum+total.TimeOverDeskNum)==0?0: total.TimeOverDeskNum/(total.TimeNormalDeskNum+total.TimeOverDeskNum)*100;
            total.ServingOverDeskNumPercent=(total.ServingNormalDeskNum+total.ServingOverDeskNum)==0?0: total.ServingOverDeskNum/(total.ServingNormalDeskNum+total.ServingOverDeskNum)*100;
            total.FoodVerifyOverTimeNumPercent=(total.FoodTotalVerifyNum)==0?0: total.FoodVerifyOverTimeNum/(total.FoodTotalVerifyNum)*100;

            return total;
        },
    },
    watch:{
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.$refs.elTable){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable,
                    titleName:"菜品实时状态监控表",
                    list:this.tableList
                })
            }

           
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList;
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]= parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[1]="合计";
            return sums;
        },
        /**查询 */
        GetBillCheck(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.FoodServingOrderMonitorReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row, column, cell, event){
            console.log(row, column, cell, event)
            if(column.property=="TimeNormalDeskNum" || column.property=="TimeOverDeskNum" || column.property=="ServingNormalDeskNum" || column.property=="ServingOverDeskNum"){//穿透 
                let type=1,name="按时间分析—正常桌数";
                if(column.property=="TimeNormalDeskNum" ){
                    if(row.TimeNormalDeskNum==0){ return;}
                }else if(column.property=="TimeOverDeskNum"){
                    if(row.TimeOverDeskNum==0){ return;}
                    type=2;
                    name="按时间分析—超时桌数";
                }else if(column.property=="ServingNormalDeskNum"){
                    if(row.ServingNormalDeskNum==0){ return;}
                    type=3;
                    name="按上菜顺序分析—正常桌数";
                }else if(column.property=="ServingOverDeskNum"){
                    if(row.ServingOverDeskNum==0){ return;}
                    type=4;
                    name="按上菜顺序分析—异常桌数";
                }
                this.$emit("addFrame",{
                    key:"analysis1"+type+row.Rpt_Date,
                    data:{
                        //1：按时间分析—正常桌数  2：按时间分析—超时桌数  3：按上菜顺序分析—正常桌数  4：按上菜顺序分析—异常桌数
                        type:type, 
                        Rpt_Date:row.Rpt_Date
                    },
                    title:name,
                    url:'reportEatAnalysis'
                });
            }else if(column.property=="RushDeskNum"){//催菜
                if(row.RushDeskNum==0){ return;}
                this.$emit("addFrame",{
                    key:"eatUrgeFood"+row.Rpt_Date,
                    data:{
                        Rpt_Date:row.Rpt_Date
                    },
                    title:'催菜分析表',
                    url:'reportEatUrgeFood'
                });
            }else if(column.property=="Rpt_Date"){//营业日期
                this.$emit("addFrame",{
                    key:"eatserveDetail"+row.Rpt_Date,
                    data:{
                        Rpt_Date:row.Rpt_Date,
                    },
                    title:'上菜顺序速度明细表',
                    url:'reportEatserveDetail'
                });
            }
        },
    }
}
</script>

<style lang="scss" >
@import './reportEatserve.scss'
</style>